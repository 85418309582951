@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html{
  font-family: "Dosis", sans-serif;
}
*{
  font-family: "Dosis", sans-serif;
  font-weight: 500;
}
.fc-event-title.fc-sticky{
  font-weight: 700 ;
}

.App {
  text-align: center;
}

.alert {
  background: #ffe9e9;
  border-radius: 32px;
}

.button-color {
  background: linear-gradient(
    90deg,
    rgba(40, 137, 17, 0.99) 25.81%,
    #7eb515 118.55%,
    #78be1d 118.55%,
    #8cc323 118.55%
  );
}
.button-gris {
  background: #d2d2d2;
}

.border-color {
  border: 1px solid rgba(40, 137, 17, 0.99);
}

.card-payement {
  border-color: #7eb515;
}

.icon-lineaire {
  color: #7eb515;
}

.btn {
  transition: linear 300ms;
}

.survole {
  background-color: #1c5b0d;
}

.seletectedBtn {
  background-color: #206f0d;
}

.text-color {
  color: #288911;
  font-family: "Dosis";
}

.stage-text {
  color: #7d7d7d;
}

.stage-suivant-bg {
  background-color: #d2d2d2;
}

.deja-un-compte {
  color: #8a8a8a;
  font-family: "Dosis";
}

.etape-suivante {
  color: #aaaaaa;
  font-family: "Dosis";
}

.reset {
  font-family: "Dosis";
  color: #000000;
}

.bg-selected-question {
  background-color: #206e0d;
}

.bg-question {
  background-color: #268210;
}

.hover-Question:hover {
  background-color: #206e0d;
  
}

.questionTitle:focus {
  outline: none !important;
  border-color: #8cc323;
  color: black;
}

.hover-form:hover {
  background-color: #268210;
  color: white;
}

.envoyer-mail {
  font-family: "Dosis";
  color: #b0b0b0;
}

.creer-formulaire {
  font-family: "Dosis";
  color: #b8b8b8;
}

.inscrire {
  font-family: "Dosis";
  color: #2a8a13;
}
.bg-whiteCustomer {
  background: #ffffff;
}

.checkbox-round {
  border-color: black;
}

.sup {
  position: absolute;
  top: -14px;
  right: -15px;
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  box-shadow: none !important;
  border-color: transparent !important;
}

.input-group-1:focus {
  border-color: #95c11f;
  outline: none;
}

.inpute-group-1:focus {
  border: transparent;
  outline: none;
}

/* .ajoutCat{
  background-color: #878787;
} */

.font {
  color: #95c11f;
}

.focus\:ring-1:focus {
  outline: none !important;
  box-shadow: 0 0 0 2px transparent !important;
}

.but {
  background: #288911;
}

.inp {
  background: #288911;
  color: white;
}

.butButton {
  background: #579810;
}
.butop {
  background: #288911;
  opacity: 0.6;
}

.cateAjout {
  background-color: #cdcdcd;
}

.cateAjout ::placeholder {
  color: #878787;
}

#button:hover {
  background-color: #288911;
}

#annuler:hover {
  background-color: #288911;
}

#creer:hover {
  background-color: #288911;
  color: white;
}

#creer {
  border-color: #288911;
  transition: all 0.2s ease;
}

#danger:hover {
  background-color: #D92D20;
  color: white;
}

#danger {
  border-color: #D92D20;
  border-radius: 8px;
  transition: all 0.2s ease;
  min-width: 150px;
}
#danger-radius:hover {
  background-color: #D92D20;
  color: white;
}

#danger-radius {
  border-color: #D92D20;
  transition: all 0.2s ease;
}

.text-color {
  color: #288911;
  font-family: "Dosis";
}

.text-number {
  color: #aaaaaa;
}

.Time {
  color: #888888;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}

.step5-formulaires-color {
  background-color: #16963b;
}

.step5-condition-color {
  background-color: #56a118;
}

.validate-test {
  background-color: #95c11f;
}

/* step5 */
.formula-number {
  background-color: #93c01f;
}
.formula-name {
  background-color: #5f5f5f;
}
.formula-condition {
  background-color: #e02041;
}

.score-to-validate {
  background-color: #e02041;
}

/* step6 */

.etapes-locked {
  color: #bbbbbb;
}

/* question */
.question-bg {
  background-color: #eeeeee;
}

.wrong-question {
  background-color: #de2041;
}

.session {
  background-image: url("./images/GREY.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.tableCommunCateg tr:first-child td:first-child {
  border-radius: 22px 0 0 0;
}

.tableCommunCateg tr:first-child td:last-child {
  border-radius: 0 22px 0 0;
}

.tableCommunCateg tr:last-child td:first-child {
  border-radius: 0 0 0 22px;
}

.tableCommunCateg tr:last-child td:last-child {
  border-radius: 0 0 22px 0;
}
.tableCommunCateg {
  width: 96%;
}

.tableCommunCatege tr:first-child td:first-child {
  border-radius: 22px 0 0 0;
}

.tableCommunCatege tr:first-child td:last-child {
  border-radius: 0 22px 0 0;
}

.tableCommunCatege tr:last-child td:first-child {
  border-radius: 0 0 0 22px;
}

.tableCommunCatege tr:last-child td:last-child {
  border-radius: 0 0 22px 0;
}
.tableCommunCatege {
  width: 100%;
}

.credits {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 52px;
  text-align: center;
  color: #000000;
}
.btn-card {
  border-color: #828282;
}
.bienvenu {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 39px;
  text-align: center;
  letter-spacing: 0.55em;
  color: #4f4f4f;
}
.offre {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  line-height: 35px;
  text-align: center;
  color: #b8b8b8;
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

#root
  > div
  > div
  > div.flex.flex-row.flex-1
  > div
  > div.flex.flex-wrap.justify-start.gap-5.overflow-auto.py-8.px-3
  > table
  > tbody
  > tr:last-child {
  border-bottom: none !important;
}

#root
  > div
  > div
  > div.m-8
  > div:nth-child(2)
  > div.flex.justify-between
  > div.w-\[75\%\]
  > table
  > tbody
  > tr:last-child {
  border-bottom: none !important;
}

#root
  > div
  > div
  > div.h-\[90\%\].bg-slate-100.flex.flex-col.p-\[3\%\].gap-9
  > div
  > div.w-\[60\%\].h-full.rounded-xl
  > div.h-\[92\%\].bg-white.rounded-2xl.overflow-auto
  > div:last-child {
  border-bottom: none !important;
}

/* input date */
input[type="date"],
input[type="time"] {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding-left: 0.75rem;
  display: flex;
  justify-content: center;
  position: relative;
  width: 95%;
  -moz-appearance: textfield;
  -webkit-appearance: none;
  margin: 0;
}

input[type="date"]::placeholder,
input[type="time"]::placeholder {
  font-family: "Roboto";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="date"]::-webkit-datetime-edit,
input[type="time"]::-webkit-datetime-edit {
  text-align: center;
  width: 100%;
}

.check-container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checke-container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checke-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border: 2px solid #62a714;
  border-radius: 15px;
}

/* On mouse-over, add a grey background color */
.check-container:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.checke-container input[type="radio"]:checked ~ .checkmark {
  background-color: white;
}

.checke-container:hover input ~ .checkmark {
  background-color: transparent;
}
.check-container input:checked ~ .checkmark {
  background-color: #62a714;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
  left: 10px;
  top: 5px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checke-container input:checked ~ .checkmark:after {
  display: block;
}

.checke-container .checkmark:after {
  left: 10px;
  top: 5px;
  width: 6px;
  height: 12px;
  border: solid #62a714;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.scrollbar {
  scrollbar-width: none;
}

.scrollbar::-webkit-scrollbar {
  width: 0;
  display: none;
  background: transparent; 
}

.webviewer{
  height: 80vh;
}

.table-tawilind  {
  border-collapse: collapse;
  max-height: 60%;
}
.table-tawilind th,
.table-tawilind td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}
.table-tawilind th {
  background-color: #f2f2f2;
}


.old-c .fc .fc-daygrid-day.fc-day-today{
  background-color:rgb(105 150 101 / 15%);
}


.old-c .fc-timegrid-slot.fc-timegrid-slot-lane{
  background-color:white;
}

.old-c .fc-timegrid-slot.fc-timegrid-slot-lane.fc-timegrid-slot-minor{
  background-color:white;
} 


.old-c .fc .fc-button-primary:not(:disabled).fc-button-active, .old-c .fc .fc-button-primary:not(:disabled):active {
  background-color: #95C11F;
  border-color: #fff;
  color: #fff;
}


.old-c .fc .fc-button-primary {
  background-color: #FBFBFB;
  border-color: #fff;
  color: #288911;
}

.old-c .fc .fc-button-primary:hover{
  background-color: #95C11F;
  border-color: #fff;
  color: #fff;
}

.old-c .fc .fc-button {
  border-radius: 0.5rem;


}
.old-c .fc .fc-button-primary:disabled {
  background-color: #288911;
  border-color: #fff;
  color:#fff;
}

.new-c .fc-toolbar-title {
  text-transform: capitalize;
}
.new-c .fc-prev-button , .new-c .fc-next-button{
  background-color: unset !important;
  border-color: #fff !important;
  color: #2c3e50;
}

.new-c .fc .fc-button-primary:focus {
  box-shadow: unset !important;
  color: #2c3e50;
}
.new-c .fc .fc-button-primary:hover {
  box-shadow: unset !important;
  color: #2c3e50;
}

input.rmdp-input {
  border: 0;
  width: 0;
  height: 0;
}

input.rmdp-input:focus {
  outline: none; /* Remove the default focus outline */
  border:none;
}

.rmdp-ep-arrow::after{
  visibility: hidden;
}

.event-className{
  display: flex;
  width:100%;
}

.popover-custom-style{
  background:"#fff";
  z-index:30;
  box-Shadow:0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.new-c .fc .fc-daygrid-day.fc-day-today {
  background-color: #e5f0f5 !important;
}

.new-c .fc-col-header-cell a {
  color: #767676 !important;
  font-weight: 500 !important;
}

.new-c .fc-theme-standard td, .fc-theme-standard th {
  border: 1px solid #ededed;
}

.new-c  .fc-day-today .fc-daygrid-day-top .fc-daygrid-day-number {
  margin: 4px;
  width: 24px;
  height: 24px;
  text-align: center;
  font-weight: bold !important;
  padding: 0 !important;
  background: #006aa3;
  color:#fff;
  border-radius: 50%;
}

.new-c .fc-daygrid-day.fc-day.fc-day-other,.new-c  .fc .fc-daygrid-day.fc-day-today.fc-day-other {
  background-color: #ededed !important;
  background-clip: padding-box;
  border: 1px solid #dbdbdb;
}

.new-c  .fc .fc-daygrid-day-top {
  display: flex;
  flex-direction: row-reverse;
}

.new-c  .fc-daygrid-day-top {
  justify-content: center;
}


.new-c  .fc-dayGridMonth-view .fc-daygrid-day-frame {
  min-height: 150px !important;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.react-tel-input .form-control:focus {
  border-color: #95c11f !important;
  outline: none !important;
  box-shadow: none !important;
}

.react-tel-input .selected-flag .arrow {
  position: relative !important;
    top: 50% !important;
    margin-top: -1px !important;
    left: 29px !important;
    width: 13px !important;
    height: 10px !important;
    border-left: unset !important;
    border-right: unset !important;
    border-top: unset !important;
    background: url('assets/icons/DownArrow.svg') !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}

.react-tel-input .selected-flag .arrow.up {
  border-bottom: unset !important;
  background: url('assets/icons/UpArrow.svg') !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.custom-b-sh {
  box-shadow: 0px 4px 30px 0px #00000008;
}

.cardestim *{
  margin: auto;
}
.cardestim {
  border-radius: 22px;
  transition: all 0.3s ease;
}
.cardestimcustom *{
  margin: auto;
}
.main_q_formateur{
  height: calc(94vh - 60px);
}

.saved_answers_popup {
  position: fixed;
  z-index: 10;
  top: 0;
  width: 80vw;
  height: 100vh;
}

@media only screen and (max-width: 920px) {
  .saved_answers_popup {
    width: 100%;
  }
}

.fc-daygrid-dot-event.fc-event-mirror, .fc-daygrid-dot-event:hover{
  background: unset !important;
}
.fc .fc-highlight {
  background: unset !important;
}

.swift-transition {
  transition: all 0.3s ease;
}

.formList{
  height: calc(100vh - 152px);
  overflow: scroll;
}
.delete-icon:hover > path {
      stroke: #FA4E4E !important;
      transition: all 0.3s ease;
}
.download-file-link:hover .download-icon > path {
      fill: #69b1ff !important;
      transition: all 0.3s ease;
}
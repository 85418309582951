.event-popover__inner {
    text-align: left;
    max-width: 417px;
    width: 417px;
    padding: 5px 8px;
    position: relative;
    background-color: #fff;
    border-radius: 8px;
}

.popper__inner {
    padding: 0;
    color: #000;
    border-radius: 3px;
    overflow: hidden;
    background: #fff;
}

.event-popover__inner .event-popover__top-right-actions {
    display: flex;
    gap: 4px;
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
    z-index: 100 !important;
    opacity: .7 !important;
    border-radius: 22px !important;
    align-items: center;
    gap: 10px
}

.event-popover__inner .illustration-header {
    height: 100px;
    overflow: hidden;
    margin-bottom: 10px;
    background-color: #ededed;
    margin: -5px 0 5px -8px;
    width: 417px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.event-popover__inner .illustration-header {
    max-height: 150px;
}

.event-popover__inner .property-text {
    display: flex;
    width: 100%;
    align-items: flex-start;
    margin-top: 10px;
    width: 100%;
    gap: 10px;
}

.popup-svg {
    width: 200px;
    margin: 0 auto;
    padding: 20px;
}

.event-popover__inner textarea {
    font-size: 15px;
    background-color: #fff;
    color: #222222;
    /* border: 2px solid #949494; */
    height: 36px;
    outline: none;
    border-radius: 10px;
    text-overflow: ellipsis;
    cursor: pointer;
    margin: 0px 3px 0px 0;
    padding:0px 12px;
    width: 100%;
    overflow: hidden; 
    overflow-wrap: break-word; 
    text-align: start; 
    height: 46px;
}
.lieu {
    max-height: 46px;
}

.event-popover__inner .event-title, .lieu  {
    font-size: 15px;
    background-color: #fff;
    color: #222222;
    border: 2px solid #949494;
    height: 36px;
    outline: none;
    border-radius: 10px;
    text-overflow: ellipsis;
    cursor: pointer;
    margin: 3px 3px 3px 0;
    padding:  12px;
    width: 100%;
}

.property-text__input {
    width: 100%;
}

.event-popover__inner .description {
    overflow: hidden; 
    overflow-wrap: break-word; 
    text-align: start; 
    height: 64px;
    max-height: 64px;
}

.save-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #006aa3;
    color: #fff;
    position: relative;
    width: fit-content;
    overflow: hidden;
    border: 0;
    padding: 0;
    min-height: 44px;
    min-width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 22px;
    padding: 0 12px;
    margin: 0 auto;
    margin-top: 30px;
}

.start-end-dates {
    margin:10px 0px;
    display: flex;
    gap: 10px;
}

.event-popover__inner .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-width: 2px !important;
    border-color: #949494 !important;
    border-radius: 10px !important;

}

.lieu, .description{
    padding-top: 5px !important;
}
.dicon{
    padding-top: 5px !important;
}

.more {
    cursor: pointer;
    width: 30px;
}

.delete-event-item {
    gap: 10px;
}
.main-offline-page-container{
    margin: 200px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 23px;
    background: url('../../assets/connexion-check-bg.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding: 100px ;
}
.main-offline-page-container h1 {
    font-family: 'Inter';
    margin: 0px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #0f0d0d;
}
.main-offline-page-container p{
    margin: 0px;
    font-family: 'Inter';
    font-weight: 200;
    font-size: 18px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
}

@media only screen and (max-width: 600px) {
    .main-offline-page-container {
        background: #FAFAFA;
        padding: 15px;
        max-width: 380px;
        width: 95%;
    }
}
.card-foot {
  height: 75px;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  /* background-color: #e02041; */
}

.icons {
  max-width: 30%;
  margin: 20px auto;
}

.visual-pic {
  margin-top: 15% !important;
}

.profile-pic {
  margin-bottom: -31%;
}

.card-info_title {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 34px;
  text-align: center;
  color: #288911;
  text-transform: capitalize;
  margin: 15px auto;
  width: fit-content;
}

.cardInfo_score {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 43px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  width: fit-content;
  margin: 7% auto;
  padding: 3% 13%;
  border-radius: 12px;
}

.remove-pic {
  justify-content: center;
  align-items: center;
  background-color: #00000017;
  border-bottom-right-radius: 22px;
  /* width: 25%; */
  margin: 0;
}

.card-foot_text {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  text-transform: capitalize;
}
.weekly_event {
    background: #B7E6FB;
    border-left: 3px solid #0EA5E9;
    border-top: unset;
    border-right: unset;
    border-bottom: unset;
    padding: 6px;
    border-radius: 4px;
    color: #000;
    font-family: 'Inter';
    font-style: normal;
    overflow: hidden;
    height: 100%;
    .event_title, .event_time {
        color: #0369A1;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
    }
    .event_description {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #000000;
    }
    &.lightskyblue {
        background: #B7E6FB !important;
        border-left: 3px solid #0EA5E9 !important;
        .event_title, .event_time {
            color: #0369A1 !important;
        }
    }
    &.darkseagreen {
        background: #d4fbca !important;
        border-left: 3px solid #5de03d !important;
        .event_title, .event_time {
            color: #5de03d !important;
        }
    }
    &.green {
        background: #c5ffb8 !important;
        border-left: 3px solid #288911 !important;
        .event_title, .event_time {
            color: #1b7507 !important;
        }
    }
    &.yellowgreen {
        background: #e6fbac !important;
        border-left: 3px solid #95C11F !important;
        .event_title, .event_time {
            color: #749b0a !important;
        }
    }
    &.darkorchid {
        background: #e1c9f8 !important;
        border-left: 3px solid #A855F7 !important;
        .event_title, .event_time {
            color: #7c28ca !important;
        }
    }
    &.crimson {
        background: #ffccd5 !important;
        border-left: 3px solid #DB1D3E !important;
        .event_title, .event_time {
            color: #c31230 !important;
        }
    }
    &.violet {
        background: #f4d1f7 !important;
        border-left: 3px solid #F584FF !important;
        .event_title, .event_time {
            color: #b834c4 !important;
        }
    }
    &.yellow {
        background: #fdfbc3 !important;
        border-left: 3px solid #FFF500 !important;
        .event_title, .event_time {
            color: #9f9a03 !important;
        }
    }

}

.fc-timegrid-event-harness-inset .fc-timegrid-event {
    background: unset !important;
    border: unset !important;
    box-shadow: unset !important;
}